

// import React, { useState } from 'react';
// import { useLocation, useNavigate } from 'react-router-dom';
// import "./SearchJobsResults.css";
// import JobCardReUse from '../../reusable/jobcardreuse/JobCardReUse';
// import SideFilters from '../../reusable/sidefilters/SideFilters';

// interface JobDetails {
//   id: number;
//   jobRole: string;
//   companyName: string;
//   package: string;
//   experience: string;
//   locations: string[];
//   education: string;
//   skills: string[];
//   postedDate: string;
//   image: string;
// }

// const SearchJobsResults: React.FC = () => {
//   const location = useLocation();
//   const navigate = useNavigate();
//   const { skills, location: jobLocation } = location.state || { skills: '', location: '' };

  
//   const [selectedFilters, setSelectedFilters] = useState({
//     experience: '',
//     workMode: '',
//     jobType: '',
//     location: '',
//     department: '',
//     education: '',
//     companyType: '',
//   });




//   const handleFilterChange = (filterType: string, filterValue: string) => {
//     setSelectedFilters((prevFilters) => ({
//       ...prevFilters,
//       [filterType]: filterValue,
//     }));
//   };



//   const handleCardClick = (jobId: number) => {
//     navigate(`/jobdetails/${jobId}`);
//   };

//   const jobSearchResults: JobDetails[] = [
//     {
//       id: 1,
//       jobRole: 'Software Engineer',
//       companyName: 'Veritis Group Inc.',
//       package: '13-18 Lacs',
//       experience: '3-6',
//       locations: ['New York', 'San Francisco'],
//       education: 'Bachelors',
//       skills: ['JavaScript', 'React', 'Node.js'],
//       postedDate: 'April 25, 2024',
//       image: 'https://cdn-icons-png.flaticon.com/512/732/732221.png',
//     },
    
//     {
//       id: 2,
//       jobRole: 'UX/UI Designer',
//       companyName: 'Veritis Group Inc.',
//       package: '38 Lacs',
//       experience: '6-10',
//       education: 'Bachelors',
//       locations: ['India', 'San Francisco'],
//       skills: ['UX Research', 'UX Designs', 'Node.js', 'Figma'],
//       postedDate: 'April 25, 2024',
//       image: 'https://cdn-icons-png.flaticon.com/512/564/564419.png',
//     },
//   ];



  
//   const filteredJobs = jobSearchResults.filter(
//     (job) =>
//       job.skills.some((skill) => skill.toLowerCase().includes(skills.toLowerCase())) &&
//       job.locations.some((loc) => loc.toLowerCase().includes(jobLocation.toLowerCase())) &&
//       (selectedFilters.experience ? job.experience === selectedFilters.experience : true) &&
//       (selectedFilters.workMode ? selectedFilters.workMode === 'remote' || selectedFilters.workMode === 'onsite' : true) &&
//       (selectedFilters.jobType ? selectedFilters.jobType === 'full-time' || selectedFilters.jobType === 'part-time' : true) &&
//       (selectedFilters.location ? job.locations.includes(selectedFilters.location) : true) &&
//       (selectedFilters.department ? job.education === selectedFilters.education : true) &&
//       (selectedFilters.companyType ? job.companyName === selectedFilters.companyType : true)
//   );

//   const searchjobscount = filteredJobs.length.toString();
//   const showingresultscount = `Showing 1 - ${filteredJobs.length} of ${jobSearchResults.length} jobs`;
//   const resultsCompanies = filteredJobs.map(job => job.companyName);

//   return (
//     <div className='inner-bg py-5'>
//       <div className='container'>
//         <div className='row'>
//           <div className='col-md-3'>
//             <div className='pb-4'>
//               <h2 className='d-flex justify-content-start h3'>{searchjobscount} Search Results</h2>
//               <span className='d-flex justify-content-start'>{resultsCompanies.join(', ')}</span>
//             </div>
//             <SideFilters selectedFilters={selectedFilters} onFilterChange={handleFilterChange} />
//           </div>

//           <div className='col-md-8'>
//             <div>
//               <div className='d-flex justify-content-between'>
//                 <p className='d-flex justify-content-start text-secondary small'>{showingresultscount}</p>
//                 <p className='d-flex justify-content-start text-primary small'>
//                   <a className='text-decoration-none font-weight-bold' href="/jobProfileForms">Upload your CV</a>
//                 </p>
//               </div>
//               {filteredJobs.map((job) => (
//                 <JobCardReUse
//                   key={job.id}
//                   jobRole={job.jobRole}
//                   companyName={job.companyName}
//                   package={job.package}
//                   education={job.education}
//                   experience={job.experience}
//                   locations={job.locations}
//                   skills={job.skills}
//                   postedDate={job.postedDate}
//                   image={job.image}
//                   onClick={() => handleCardClick(job.id)}
//                 />
//               ))}
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default SearchJobsResults;







import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Card, Dropdown, Form } from 'react-bootstrap';
import "./SearchJobsResults.css";
import JobCardReUse from '../../reusable/jobcardreuse/JobCardReUse';
import SideFilters2 from '../../reusable/sidefilters/SideFilters2';


const SearchJobsResults: React.FC = () => {
  const savedjobscount = '30';
  const showingresultscount = "1 - 15 of 24039";
  const savedCompanies = ['Tech Mahindra', 'TCS', 'Facebook', 'etc...'];
  const workModes = ['WFH', 'WFO', 'Hybrid'];
  const jobTypes = ['Perminanat', 'Contract', 'Part Time'];
  const locations = ['New York City','Detroit' ,'Kansas City' ,'Chicago']
  const departments = ['IT','Finance','Banking']
  const educations = ['Graduation','Masters' ,'MBA']
  const companyType = ['MNC','Startup']


  interface JobCardReUseProps {
    id: number;
    jobRole: string;
    companyName: string;
    package: string;
    experience: string;
    locations: string[];
    education: string;
    skills: string[];
    postedDate: string;
    image: string;
  }

  interface FilterCardsProps {
    id: number;
    yearsexperience?: string;
    filtertittle: string;
    options?: string[];
  }

  const jobRecommendations: JobCardReUseProps[] = [
    {
      id: 1,
      jobRole: 'Software Engineer',
      companyName: 'Microsoft Inc.',
      package: '$17.5K - $24.2K',
      experience: '3-6',
      locations: ['New York', 'San Francisco'],
      education:'Becherlors in Electrical, ',
      skills: ['JavaScript', 'React', 'Node.js'],
      postedDate: 'Today',
      image: 'https://cdn-icons-png.flaticon.com/512/732/732221.png',
    },
    
    {
      id: 2,
      jobRole: 'UX/UI Designer',
      companyName: 'Pixel Inc.',
      package: '$16.2K - $23.1K',
      experience: '6-10',
      education:'Becherlors in Computers, ',
      locations: ['Manchester', 'San Francisco'],
      skills: ['UX Research', 'UX Designs', 'Node.js', 'Figma'],
      postedDate: 'April 25, 2024',
      image: 'https://cdn-icons-png.flaticon.com/512/564/564419.png',
    },

    {
      id: 3,
      jobRole: 'Software Engineer',
      companyName: 'Microsoft Inc.',
      package: '$17.5K - $24.2K',
      experience: '3-6',
      locations: ['New York', 'San Francisco'],
      education:'Becherlors in Automobile, ',
      skills: ['JavaScript', 'React', 'Node.js'],
      postedDate: 'Today',
      image: 'https://cdn-icons-png.flaticon.com/512/732/732221.png',
    },
    {
      id: 4,
      jobRole: 'UX/UI Designer',
      companyName: 'Pixel Inc.',
      package: '$16.2K - $23.1K',
      experience: '6-10',
      education:'Becherlors in Arts, ',
      locations: ['Manchester', 'San Francisco'],
      skills: ['UX Research', 'UX Designs', 'Node.js', 'Figma'],
      postedDate: 'April 25, 2024',
      image: 'https://cdn-icons-png.flaticon.com/512/564/564419.png',
    },
  ];


  const filtercards: FilterCardsProps[] = [
    {
      id: 1,
      filtertittle: "Experience",
      yearsexperience: "0-20 Years",
    },
    {
      id: 2,
      filtertittle: "Work Mode",
      options: workModes,
    },
    {
      id: 3,
      filtertittle: "Job Type",
      options: jobTypes,
    },
    {
      id: 4,
      filtertittle: "Locations",
      options: locations,
    },
    {
      id: 5,
      filtertittle: "Department",
      options: departments,
    },
    {
      id: 6,
      filtertittle: "Education",
      options: educations,
    },
  ];


  const location = useLocation();
  const navigate = useNavigate();
  const { skills, location: jobLocation } = location.state || { skills: '', location: '' };



const handleJobClick = (jobId: number) => {
  navigate(`/jobdetails/${jobId}`);
};

const handleCompanyClick = (companyName: string) => {
  navigate(`/companydetails/${companyName}`);
};

const handleImageClick = (jobId: number) => {
  navigate(`/jobdetails/${jobId}`);
};




  return (
    <div className='js-pg-hg'>
      <div className='container'>
        <div className='row'>

          <div className='col-md-4'>
            <div className='pb-4'>
              <h2 className='d-flex justify-content-start h3'>{savedjobscount} Search Results</h2>
              <span className='d-flex justify-content-start'>{savedCompanies.join(', ')}</span>
            </div>
            <div>
              {filtercards.map((filter) => (
                <SideFilters2
                  key={filter.id}
                  filtertittle={filter.filtertittle}
                  yearsexperience={filter.yearsexperience}
                  options={filter.options}
                />
              ))}
            </div>
          </div>

          <div className='col-md-8'>
            <div>
              <div className='d-flex justify-content-between'>
              <p className='d-flex justify-content-start text-secondary small'>{showingresultscount}</p>
              <p className='d-flex justify-content-start text-primary small'><a className='text-decoration-none fw-bold' href="/jobProfileForms">Upload your Resume </a></p>
              </div>
              {jobRecommendations.map((job) => (
                <JobCardReUse
                  key={job.id}
                  jobRole={job.jobRole}
                  companyName={job.companyName}
                  package={job.package}
                  education={job.education}
                  experience={job.experience}
                  locations={job.locations}
                  skills={job.skills}
                  postedDate={job.postedDate}
                  image={job.image}
                  onJobClick={() => handleJobClick(job.id)}
                  onImageClick={() => handleImageClick(job.id)}
                  onCompanyClick={() => handleCompanyClick(job.companyName)}
                  
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchJobsResults;





