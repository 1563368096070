import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './jobseekerdashboard.css';
import { Dropdown, Card, Badge, Button, DropdownButton } from 'react-bootstrap';
import Avatar from '../../reusable/avatar/Avatar';
import ResumeModal from '../../reusable/resumemodal/ResumeModal';
import ContactUpdateModal from '../../reusable/contactupdatemodal/ContactUpdateModal';
import TopSkillsModal from '../../reusable/topskillsmodal/TopSkillsModal';
import EducationModal from '../../reusable/educationmodal/EducationModal';
import { Link } from 'react-router-dom';
import userAvatar from '../../assets/images/profileimg.jpg';
import documentationImg from "../../assets/icons/documentation.png";
import contacts from "../../assets/icons/contacts.png";
import { MdOutlineArrowDropDown } from "react-icons/md";
import { RiVerifiedBadgeFill } from "react-icons/ri";


import JobCardReUse from '../../reusable/jobcardreuse/JobCardReUse';


import { BsFillCaretDownFill } from 'react-icons/bs';
import { Modal, Form } from 'react-bootstrap';



interface DropdownItem {
Item: string;
}
const JobSeekerDashboard: React.FC = () => {
const userName = 'DenCook'; // Replace with user's name
const profileCompleted = '10%'; // Replace with user's name
// const userAvatarUrl = '../../assets/images/Adobe.png';
const DropdownList: DropdownItem[] = [
{ Item: 'Yes Im looking' },
{ Item: 'I am not looking' },
];
const [selectedItem, setSelectedItem] = useState(DropdownList[0].Item);
const handleSelect = (eventKey: string | null) => {
if (eventKey !== null) {
const item = DropdownList.find((item) => item.Item === eventKey);
if (item) {
setSelectedItem(item.Item);
}
}
};

const activityData = [
{ activity: 'Profile', activityState: 'No' },
{ activity: 'Key Skills', activityState: 'No' },
{ activity: 'Work Experience', activityState: 'No' },
{ activity: 'Education', activityState: 'No' },
{ activity: 'Updated Resume', activityState: 'No' },
];


const appliedDate = [
   { datevalue: 'Overall', className: 'btn-primary text-white'},
   { datevalue: 'This month',},
   { datevalue: 'Last 6 months',},
   ];


const jobsappliedcount = '30';


 interface JobCardReUseProps {
   id: number;
   jobRole: string;
   companyName: string;
   package: string;
   experience: string;
   locations: string[];
   education?: string;
   skills: string[];
   postedDate: string;
   image: string;
 }




 const latestSavedJobs: JobCardReUseProps[] = [
   {
     id: 1,
     jobRole: 'Software Engineer',
     companyName: 'Microsoft Inc.',
     package: '$17.5K - $24.2K',
     experience: '3-6',
     locations: ['New York'],
     skills: ['JavaScript..'],
     postedDate: 'April 25',
     image: 'https://cdn-icons-png.flaticon.com/512/732/732221.png',
   },

   {
     id: 2,
     jobRole: 'UX/UI Designer',
     companyName: 'Pexel LLC.',
     package: '$17.5K - $24.2K',
     experience: '6-10',
     locations: ['Jersey City'],
     skills: ['UX Research..'],
     postedDate: 'April 25',
     image: 'https://cdn-icons-png.flaticon.com/512/564/564419.png',
   },

   {
      id: 3,
      jobRole: 'UX/UI Designer',
      companyName: 'NR Media Inc.',
      package: '$17.5K - $24.2K',
      experience: '6-10',
      locations: ['Buffalo'],
      skills: ['UX Designs..'],
      postedDate: 'April 25',
      image: 'https://cdn-icons-png.flaticon.com/512/564/564411.png',
    },




 ];



 const [jobLooking, setJobLooking] = useState('');
 const jobStatus = ['Yes, Iam Looking', 'Not Now'];


 const location = useLocation();
 const navigate = useNavigate();


 const handleJobClick = (jobId: number) => {
   navigate(`/jobdetails/${jobId}`);
 };

 const handleCompanyClick = (companyName: string) => {
   navigate(`/companydetails/${companyName}`);
 };

 const handleImageClick = (jobId: number) => {
   navigate(`/jobdetails/${jobId}`);
 };



return (
<div>
   <div className='js-pg-hg'>
      <div className='container'>
         <div className='header-sub-height'>
         <div className='d-flex justify-content-start h3 fw-bold'>Welcome {userName}</div>
            <span className='d-flex justify-content-start align-items-center'>Your profile is {profileCompleted} Completed</span>
         </div>

         <div className='row'>
         <div className='col-md-3'>

            <div className=''>

<Form.Group controlId="jobLooking" className="mb-4">
        <Form.Label className='d-flex justify-content-start'>Are you looking for a job now?</Form.Label>
        <div className="border shadow-none rounded-4 p-4 mb-4 card">
          <Form.Control
            as="select"
            className={`form-control-custom form-select-with-icon ${!jobLooking ? 'placeholder' : ''}`}
            value={jobLooking}
            onChange={(e) => setJobLooking(e.target.value)}
          >
            <option value="">Select</option>
            {jobStatus.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </Form.Control>

        </div>
</Form.Group>



            </div>




            <div>
               <Card className='border shadow-none rounded-4 mb-4'>
                  <Card.Body className='p-2'>
                     <div className='mb-4 p-4'>
                        <div className='d-flex justify-content-center pb-4'>
                           <Avatar imageUrl={userAvatar}  altText={userName} />
                        </div>
                        <span className='fw-medium'>{userName}</span>
                     </div>

                     <div  className='p-4 py-0'>
                        {activityData.map((card, index) => (
                        <div key={index} className='mb-4'>
                           <li className='d-flex justify-content-between align-items-start'>
                              <div>{card.activity}</div>
                              <div>
                                 <div className='badge text-secondary fw-400'>{card.activityState}</div>
                              </div>
                           </li>
                        </div>
                        ))}
                     </div>
                  </Card.Body>

                  <Link to="/jobProfileForms" className='w-100'>
                     <Button variant="primary" className="mt-auto p-3 w-100 profile-btn">Edit Profile and Skills</Button>
                  </Link>
               </Card>


               <Card className='border shadow-none rounded-4 p-2 mb-4'>
                  <Card.Body className='py-3'>
                     <div className='d-flex justify-content-between align-items-center font-weight-normal px-1'>
                        <div className='d-flex align-items-center'>
                           <div className='text-start'>
                              <div className='text-secondary'>Contact Number</div>
                              <div className='text-primary fw-medium small pt-1'>8499912198 <RiVerifiedBadgeFill className="text-primary" /></div>
                           </div>
                        </div>
                        <div><ContactUpdateModal/></div>
                     </div>
                  </Card.Body>
               </Card>
            </div>
         </div>







<div className='col-md-6'>

         <Card className='border shadow-none rounded-4 p-2 mb-4'>
                  <Card.Body className='py-4'>
                     <div className='px-3 py-2 align-items-center'>
                       <div className='d-flex justify-content-between'>
                        <div className='d-flex align-items-center'>


                           <div>
                           <img src={documentationImg} alt="Description" className='resume-icon' />
                           </div>

                           <div className='text-start mx-3'>
                              <h6 className='mb-0 ps-2 w-75 text-secondary'>Please update your resume to ensure it's up to date.</h6>
                           </div>

                        </div>
                        <div><ResumeModal></ResumeModal></div>
                     </div>
                     </div>
                  </Card.Body>
          </Card>


          <Card className='border shadow-none rounded-4 p-2 mb-4'>
                  <Card.Body>
                     <div className='d-flex justify-content-between font-weight-normal px-2 py-2'>
                        <span className='text-secondary'>Jobs Applied</span>

                        <div className='d-flex px-2'>
                        {appliedDate.map((card, index) => (
                        <div key={index} className='mb-4 px-1 d-flex align-items-center'>
                              <Button className={card.className ? `custom-button ${card.className}` : 'custom-button'} variant={card.className ? 'primary' : 'outline-primary'}><div>{card.datevalue}</div></Button>
                        </div>
                        ))}
                        </div>
                     </div>

                     <div className='d-flex justify-content-start px-2 pb-2'>
                        <h4 className='fw-semibold Text-primary'><Link to="/appliedjobs" className='text-decoration-none'>{jobsappliedcount}</Link> Applications Submitted</h4>
                     </div>
                  </Card.Body>
          </Card>




          <Card className='border shadow-none rounded-4 p-2 mb-4'>
                  <Card.Body>
                     <div className='d-flex justify-content-start align-items-center font-weight-normal px-2 py-2'>
                        <span className='text-secondary'>Your Top Skills</span>
                     </div>

                     <div>
                      <div className='mx-2 my-2 bg-skyblue'>
                        <div className='p-5'><TopSkillsModal></TopSkillsModal></div>
                        </div>
                     </div>
                  </Card.Body>
          </Card>



          <Card className='border shadow-none rounded-4 p-2 mb-4'>
                  <Card.Body>
                     <div className='d-flex justify-content-start align-items-center font-weight-normal px-2 py-2'>
                        <span className='text-secondary'>Your Education</span>
                     </div>

                     <div>
                      <div className='mx-2 my-2 bg-skyblue'>
                        <div className='p-5'><EducationModal></EducationModal></div>
                        </div>
                     </div>
                  </Card.Body>
          </Card>



         </div>



         <div className='col-md-3'>

         <div>
      <p className='d-flex justify-content-start text-secondary'>Job Recommendations</p>

              <div>
              {latestSavedJobs.map((job) => (
                <JobCardReUse
                  key={job.id}
                  jobRole={job.jobRole}
                  companyName={job.companyName}
                  package={job.package}
                  // education={job.education}
                  experience={job.experience}
                  locations={job.locations}
                  skills={job.skills}
                  postedDate={job.postedDate}
                  image={job.image}
                  onJobClick={() => handleJobClick(job.id)}
                  onImageClick={() => handleImageClick(job.id)}
                  onCompanyClick={() => handleCompanyClick(job.companyName)}
                />
              ))}
            </div>

    </div>

         </div>

         </div>

      </div>
   </div>
</div>
);
};
export default JobSeekerDashboard;
