
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './SavedJobsWrapper.css';
import { FaGoogle } from 'react-icons/fa';
import { BsXLg } from "react-icons/bs";
import { Link } from 'react-router-dom';
import { MdBookmarkBorder} from 'react-icons/md';
import { PiBookmarkSimpleLight } from "react-icons/pi";
import { NavLink } from 'react-router-dom';

import JobCardReUse from '../../reusable/jobcardreuse/JobCardReUse';



interface SavedJobsWrapperProps {
    children: React.ReactNode;
  }

  const SavedJobsWrapper: React.FC<SavedJobsWrapperProps> = ({ children }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const openDrawer = () => {
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };


  const noofsavedjobs = 6;
  const dayname = "Today";


  interface JobCardReUseProps {
    id: number;
    jobRole: string;
    companyName: string;
    package: string;
    experience: string;
    locations: string[];
    education: string;
    skills: string[];
    postedDate: string;
    image: string;
    isInitiallyBookmarked?: boolean;
  }


  const latestSavedJobs: JobCardReUseProps[] = [
    {
      id: 1,
      jobRole: 'Software Engineer',
      companyName: 'Veritis Group Inc.',
      package: '$16.2K - $23.1K',
      experience: '3-6',
      locations: ['New York', 'San Francisco'],
      education:'Becherlors, ',
      skills: ['JavaScript', 'React', 'Node.js'],
      postedDate: 'April 25, 2024',
      image: 'https://cdn-icons-png.flaticon.com/512/732/732221.png',
      isInitiallyBookmarked: true,
    },

    {
      id: 2,
      jobRole: 'UX/UI Designer',
      companyName: 'Veritis Group Inc.',
      package: '$18.2K - $28.7K',
      experience: '6-10',
      education:'Becherlors, ',
      locations: ['Texas', 'San Francisco'],
      skills: ['UX Research', 'UX Designs', 'Node.js', 'Figma'],
      postedDate: 'April 25, 2024',
      image: 'https://cdn-icons-png.flaticon.com/512/564/564419.png',
      isInitiallyBookmarked: true,
    },

    {
      id: 3,
      jobRole: 'Software Engineer',
      companyName: 'Veritis Group Inc.',
      package: '$16.2K - $23.1K',
      experience: '3-6',
      locations: ['New York', 'San Francisco'],
      education:'Becherlors, ',
      skills: ['JavaScript', 'React', 'Node.js'],
      postedDate: 'April 25, 2024',
      image: 'https://cdn-icons-png.flaticon.com/512/732/732221.png',
      isInitiallyBookmarked: true,
    },

    {
      id: 4,
      jobRole: 'Software Engineer',
      companyName: 'Veritis Group Inc.',
      package: '$16.2K - $23.1K',
      experience: '3-6',
      locations: ['New York', 'San Francisco'],
      education:'Becherlors, ',
      skills: ['JavaScript', 'React', 'Node.js'],
      postedDate: 'April 25, 2024',
      image: 'https://cdn-icons-png.flaticon.com/512/732/732221.png',
      isInitiallyBookmarked: true,
    },

    {
      id: 5,
      jobRole: 'UX/UI Designer',
      companyName: 'Veritis Group Inc.',
      package: '$18.2K - $28.7K',
      experience: '6-10',
      education:'Becherlors, ',
      locations: ['Texas', 'San Francisco'],
      skills: ['UX Research', 'UX Designs', 'Node.js', 'Figma'],
      postedDate: 'April 25, 2024',
      image: 'https://cdn-icons-png.flaticon.com/512/564/564419.png',
      isInitiallyBookmarked: true,
    },

    {
      id: 6,
      jobRole: 'Software Engineer',
      companyName: 'Veritis Group Inc.',
      package: '$16.2K - $23.1K',
      experience: '3-6',
      locations: ['New York', 'San Francisco'],
      education:'Becherlors, ',
      skills: ['JavaScript', 'React', 'Node.js'],
      postedDate: 'April 25, 2024',
      image: 'https://cdn-icons-png.flaticon.com/512/732/732221.png',
      isInitiallyBookmarked: true,
    },



  ];



  const navigate = useNavigate();
  const handleSubmit2 = () => {
    navigate('/savedJobsResults');
  };



  const location = useLocation();
  const navigate2 = useNavigate();

  const handleJobClick = (jobId: number) => {
    navigate(`/jobdetails/${jobId}`);
  };

  const handleCompanyClick = (companyName: string) => {
    navigate(`/companydetails/${companyName}`);
  };

  const handleImageClick = (jobId: number) => {
    navigate(`/jobdetails/${jobId}`);
  };



  return (
    <div className="drawer-wrapper-savedjobs">
      <div className={`drawer ${isDrawerOpen ? 'open' : ''}`}>
      <div className='d-flex justify-content-between border-bottom py-3 px-3 mb-3 align-items-center vertical-bottom'>
      <div className='p-3'>
          <h5>{noofsavedjobs} Saved Jobs</h5>
        </div>
        <div className="p-3">
          <button className='btn me-4 px-4 py-3 ms-3 btn-outline-primary' onClick={handleSubmit2}>View All</button>
          {isDrawerOpen && (
            <BsXLg onClick={closeDrawer} className="form-control-close" />
          )}
        </div>
      </div>








        <div className='col-md-12 pt-3'>
            <div className='js-svjobs px-4'>

              {latestSavedJobs.map((job) => (
                <JobCardReUse
                  key={job.id}
                  jobRole={job.jobRole}
                  companyName={job.companyName}
                  package={job.package}
                  education={job.education}
                  experience={job.experience}
                  locations={job.locations}
                  skills={job.skills}
                  postedDate={job.postedDate}
                  image={job.image}
                  isInitiallyBookmarked={job.isInitiallyBookmarked}
                  onJobClick={() => handleJobClick(job.id)}
                  onImageClick={() => handleImageClick(job.id)}
                  onCompanyClick={() => handleCompanyClick(job.companyName)}
                />
              ))}
            </div>
          </div>


        <div>


        </div>





      </div>
      <div className="main-content">
        <div onClick={openDrawer} className="py-2 service-header-icons">
        <MdBookmarkBorder size={32} />
        </div>
        {children}
      </div>
    </div>
  );
};

export default SavedJobsWrapper;