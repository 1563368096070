import React, { useState } from 'react';
import './SideMenuWrapper.css';
import { BsXLg } from "react-icons/bs";
import { MdOutlineDashboard, MdBookmarkBorder, MdChatBubbleOutline, MdOutlineSettings, MdOutlineLogout } from "react-icons/md";
import userAvatar from '../../assets/images/profileimg.jpg';
import { PiDotsNine, PiBookmarkSimpleLight, PiGearLight, PiSignOutLight } from "react-icons/pi";


import { Link } from 'react-router-dom';

interface SideMenuWrapperProps {
  children: React.ReactNode;
}

const SideMenuWrapper: React.FC<SideMenuWrapperProps> = ({ children }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const openDrawer = () => {
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  // const userAvatarUrl = '../../assets/images/profileimg.jpg';
  const userName = 'DenCook Ln';
  const userRole = 'UX/UI Developer';

  const initials = userName ? userName.slice(0, 2).toUpperCase() : '';


  const randomColor = () => {
    const colors = ['#F44336', '#E91E63', '#9C27B0', '#673AB7', '#3F51B5', '#2196F3', '#03A9F4', '#00BCD4', '#009688', '#4CAF50', '#8BC34A', '#CDDC39', '#FFEB3B', '#FFC107', '#FF9800', '#FF5722', '#795548', '#9E9E9E', '#607D8B'];
    return colors[Math.floor(Math.random() * colors.length)];
  };

  return (
    <div className="drawer-wrapper-sidemenu">
      <div className={`p-4 drawer ${isDrawerOpen ? 'open' : ''}`}>



        <div className="d-flex justify-content-between align-items-center">
          <div className=''>

            <div className="d-flex align-items-center user-info mb-0">
          <div className="avatar-container me-2 avatar-container-2 d-flex align-items-center justify-content-center" style={{ backgroundColor: randomColor() }}>
            <img src={userAvatar} width={60} alt="User Avatar" className="user-avatar" />
            {/* <span className="avatar-text text-light">{initials}</span> */}
          </div>
          <div className="user-details ms-3">
            <h6 className="user-name mb-2 d-flex justify-content-start">{userName}</h6>
            <p className="user-role mb-0 d-flex justify-content-start">{userRole}</p>
          </div>
        </div>


          </div>

          <div>
            {isDrawerOpen && (
              <BsXLg onClick={closeDrawer} className="form-control-close" />
            )}
          </div>
        </div>

        <div className="close-line-container"><hr className='w-100'/></div>

            <ul className="list-group pt-3 ps-0 border-0">
            <li className="list-group-item px-3 py-3 border-0 d-flex align-items-center">
            <Link to="/JobSeekerDashboard" className="d-flex align-items-center link-dark text-decoration-none">
              <PiDotsNine className="menu-icon" size="24" />
              <span className="fw-regular ms-3">My Dashboard</span>
            </Link>
          </li>

          <li className="list-group-item px-3 py-3 border-0 d-flex align-items-center">
            <Link to="/savedJobsResults"  className="d-flex align-items-center link-dark text-decoration-none">
              <PiBookmarkSimpleLight className="menu-icon" size={24} />
              <span className="fw-regular ms-3">Saved Jobs</span>
            </Link>
          </li>

          <li className="list-group-item px-3 py-3 border-0 d-flex align-items-center">
            <Link to="/accountsettings" className="d-flex align-items-center link-dark text-decoration-none">
              <PiGearLight className="menu-icon" size={24} />
              <span className="fw-regular ms-3">Account Settings</span>
            </Link>
          </li>
          <li className="list-group-item px-3 py-3 border-0 d-flex align-items-center">
            <Link to="/"  className="d-flex align-items-center link-dark text-decoration-none">
              <PiSignOutLight className="menu-icon" size={24} />
              <span className="fw-regular ms-3">Logout</span>
            </Link>
          </li>
        </ul>

      </div>
      <div className="main-content">

        {/* <div onClick={openDrawer} className="btn avatar-container-custom d-flex align-items-center justify-content-center" style={{ backgroundColor: randomColor() }}>
          <span className="avatar-text text-light">{initials}</span>
          <img src={userAvatar} alt="User Avatar" className="user-avatar" />
        </div> */}

       <div onClick={openDrawer} className="avatar-container-custom d-flex align-items-center justify-content-center" style={{ backgroundColor: randomColor() }}>
       <img src={userAvatar} alt="User Avatar" className="user-avatar" />
      {/* <span className="avatar-text text-light">{initials}</span> */}
       </div>

        {children}
      </div>
    </div>
  );
};

export default SideMenuWrapper;
