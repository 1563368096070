import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Card, Dropdown, Form } from 'react-bootstrap';
import "./SucessApplied.css";
import JobCardReUse from '../../reusable/jobcardreuse/JobCardReUse';
import { PiCheckCircleFill } from "react-icons/pi";


const SavedJobsResults: React.FC = () => {
  const showingresultscount = "15";
  const appliedrole = 'UX/UI Developer';
  const useremail = 'Dencook@gmail.com';


  interface JobCardReUseProps {
    id: number;
    jobRole: string;
    companyName: string;
    package: string;
    experience: string;
    locations: string[];
    education?: string;
    skills: string[];
    postedDate: string;
    image: string;
    isInitiallyBookmarked?: boolean;
  }

  
  const jobsSaved: JobCardReUseProps[] = [
    {
      id: 1,
      jobRole: 'Software Engineer',
      companyName: 'Microsoft Inc.',
      package: '$17.5K - $24.2K',
      experience: '3-6',
      locations: ['New York', 'San Francisco'],
      education:'Masters, ',
      skills: ['JavaScript', 'React', 'Node.js'],
      postedDate: 'Today',
      image: 'https://cdn-icons-png.flaticon.com/512/732/732221.png',
      isInitiallyBookmarked: false,
    },
    
    {
      id: 2,
      jobRole: 'UX/UI Designer',
      companyName: 'Pixel Inc.',
      package: '$16.2K - $23.1K',
      experience: '6-10',
      education:'Masters, ',
      locations: ['Louis', 'San Francisco'],
      skills: ['UX Research', 'UX Designs', 'Node.js', 'Figma'],
      postedDate: 'April 25, 2024',
      image: 'https://cdn-icons-png.flaticon.com/512/564/564419.png',
      isInitiallyBookmarked: false,
    },
    {
      id: 3,
      jobRole: 'Software Engineer',
      companyName: 'Microsoft Inc.',
      package: '$17.5K - $24.2K',
      experience: '3-6',
      locations: ['New York', 'San Francisco'],
      education:'Masters, ',
      skills: ['JavaScript', 'React', 'Node.js'],
      postedDate: 'Today',
      image: 'https://cdn-icons-png.flaticon.com/512/732/732221.png',
      isInitiallyBookmarked: false,
    },
    
    {
      id: 4,
      jobRole: 'UX/UI Designer',
      companyName: 'Pixel Inc.',
      package: '$16.2K - $23.1K',
      experience: '6-10',
      education:'Masters, ',
      locations: ['Louis', 'San Francisco'],
      skills: ['UX Research', 'UX Designs', 'Node.js', 'Figma'],
      postedDate: 'April 25, 2024',
      image: 'https://cdn-icons-png.flaticon.com/512/564/564419.png',
      isInitiallyBookmarked: false,
    },
  ];




  const jobRecommendations: JobCardReUseProps[] = [
    {
      id: 1,
      jobRole: 'Software Engineer',
      companyName: 'Microsoft Inc.',
      package: '$17.5K - $24.2K',
      experience: '3-6',
      locations: ['New York', 'San Francisco'],
      skills: ['JavaScript', 'React', 'Node.js'],
      postedDate: 'Today',
      image: 'https://cdn-icons-png.flaticon.com/512/732/732221.png',
      isInitiallyBookmarked: false,
    },
    
    {
      id: 2,
      jobRole: 'UX/UI Designer',
      companyName: 'Pixel Inc.',
      package: '$16.2K - $23.1K',
      experience: '6-10',
      locations: ['texas', 'San Francisco'],
      skills: ['UX Research', 'UX Designs', 'Node.js', 'Figma'],
      postedDate: 'April 25, 2024',
      image: 'https://cdn-icons-png.flaticon.com/512/564/564419.png',
      isInitiallyBookmarked: false,
    },
    {
      id: 3,
      jobRole: 'Software Engineer',
      companyName: 'Microsoft Inc.',
      package: '$17.5K - $24.2K',
      experience: '3-6',
      locations: ['New York', 'San Francisco'],
      skills: ['JavaScript', 'React', 'Node.js'],
      postedDate: 'Today',
      image: 'https://cdn-icons-png.flaticon.com/512/732/732221.png',
      isInitiallyBookmarked: false,
    },
    
    {
      id: 4,
      jobRole: 'UX/UI Designer',
      companyName: 'Pixel Inc.',
      package: '$16.2K - $23.1K',
      experience: '6-10',
      locations: ['texas', 'San Francisco'],
      skills: ['UX Research', 'UX Designs', 'Node.js', 'Figma'],
      postedDate: 'April 25, 2024',
      image: 'https://cdn-icons-png.flaticon.com/512/564/564419.png',
      isInitiallyBookmarked: false,
    },
  ];



  const location = useLocation();
  const navigate = useNavigate();
 
  const handleJobClick = (jobId: number) => {
    navigate(`/jobdetails/${jobId}`);
  };
  
  const handleCompanyClick = (companyName: string) => {
    navigate(`/companydetails/${companyName}`);
  };
  
  const handleImageClick = (jobId: number) => {
    navigate(`/jobdetails/${jobId}`);
  };




  return (
    <div className='py-5'>
      <div className='container'>
       <div className='d-flex justify-content-center'>
        <div className='col-xl-8 col-lg-8 col-md-12 col-sm-12'>

        <div className='mb-5 d-flex align-items-center'>
          <div className='card'>
            <div className='card-body p-5'>
              <div className='d-flex align-items-center'>

                  <div className='d-flex align-items-center'>
                   <PiCheckCircleFill className="icon-size-sucessapplied" size={48} />
                  </div>

                  <div>
                    <h4 className="card-title text-start">You have successfully applied to {appliedrole}</h4>
                    <p className='d-flex justify-content-start text-start text-secondary small'>You will get an email confirmation at {useremail}</p>
                  </div>

              </div>  
            </div>
          </div>
        </div>
        
        </div>
       </div>




        <div className='row'>

          <div className='col-md-8'>
            <div>
              <div className='d-flex justify-content-start'>
              <p className='d-flex justify-content-start text-secondary small'>{showingresultscount} Roles you might be interested in</p>
              </div>
              {jobsSaved.map((job) => (
                <JobCardReUse
                  key={job.id}
                  jobRole={job.jobRole}
                  companyName={job.companyName}
                  package={job.package}
                  education={job.education}
                  experience={job.experience}
                  locations={job.locations}
                  skills={job.skills}
                  postedDate={job.postedDate}
                  image={job.image}
                  isInitiallyBookmarked={job.isInitiallyBookmarked}
                  onJobClick={() => handleJobClick(job.id)}
                  onImageClick={() => handleImageClick(job.id)}
                  onCompanyClick={() => handleCompanyClick(job.companyName)}
                />
              ))}
            </div>
          </div>



          <div className='col-md-4'>
            <div>
            <p className='d-flex justify-content-start p-2 fw-medium fs-6 text-secondary'>Job Recommendations</p>
            {jobRecommendations.map((job) => (
                <JobCardReUse
                  key={job.id}
                  jobRole={job.jobRole}
                  companyName={job.companyName}
                  package={job.package}
                  experience={job.experience}
                  locations={job.locations}
                  skills={job.skills}
                  postedDate={job.postedDate}
                  image={job.image}
                  isInitiallyBookmarked={job.isInitiallyBookmarked}
                  onJobClick={() => handleJobClick(job.id)}
                  onImageClick={() => handleImageClick(job.id)}
                  onCompanyClick={() => handleCompanyClick(job.companyName)}
                />
              ))}
            </div>
          </div>


        </div>
      </div>
    </div>
  );
};

export default SavedJobsResults;
