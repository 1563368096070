import React from 'react';
import JobCardReUse from '../../reusable/jobcardreuse/JobCardReUse';

interface JobCardReUseProps {
  id: number;
  jobRole: string;
  companyName: string;
  package: string;
  experience: string;
  locations: string[];
  education?: string; // Optional based on your recent requirements
  skills: string[];
  postedDate: string;
  image: string;
}

const AppliedJobs: JobCardReUseProps[] = [
  {
    id: 1,
    jobRole: 'Software Engineer',
    companyName: 'Microsoft Inc.',
    package: '$17.5K - $24.2K',
    experience: '3-6',
    locations: ['New York', 'San Francisco'],
    education: 'Bachelors',
    skills: ['JavaScript', 'React', 'Node.js'],
    postedDate: 'Today',
    image: 'https://cdn-icons-png.flaticon.com/512/732/732221.png',
  },
  {
    id: 2,
    jobRole: 'UX/UI Designer',
    companyName: 'Pixel Inc.',
    package: '$17.5K - $24.2K',
    experience: '6-10',
    locations: ['Washington', 'San Francisco'],
    education: 'Bachelors',
    skills: ['UX Research', 'UX Designs', 'Node.js', 'Figma'],
    postedDate: 'April 25, 2024',
    image: 'https://cdn-icons-png.flaticon.com/512/564/564419.png',
  },
  {
    id: 3,
    jobRole: 'Software Engineer',
    companyName: 'Microsoft Inc.',
    package: '$17.5K - $24.2K',
    experience: '3-6',
    locations: ['New York', 'San Francisco'],
    education: 'Bachelors',
    skills: ['JavaScript', 'React', 'Node.js'],
    postedDate: 'Today',
    image: 'https://cdn-icons-png.flaticon.com/512/732/732221.png',
  },
  {
    id: 4,
    jobRole: 'Software Engineer',
    companyName: 'Microsoft Inc.',
    package: '$17.5K - $24.2K',
    experience: '3-6',
    locations: ['New York', 'San Francisco'],
    education: 'Bachelors',
    skills: ['JavaScript', 'React', 'Node.js'],
    postedDate: 'Today',
    image: 'https://cdn-icons-png.flaticon.com/512/732/732221.png',
  },
  {
    id: 5,
    jobRole: 'UX/UI Designer',
    companyName: 'Pixel Inc.',
    package: '$17.5K - $24.2K',
    experience: '6-10',
    locations: ['Texas', 'San Francisco'],
    education: 'Bachelors',
    skills: ['UX Research', 'UX Designs', 'Node.js', 'Figma'],
    postedDate: 'April 25, 2024',
    image: 'https://cdn-icons-png.flaticon.com/512/564/564419.png',
  },
  {
    id: 6,
    jobRole: 'Software Engineer',
    companyName: 'Microsoft Inc.',
    package: '$17.5K - $24.2K',
    experience: '3-6',
    locations: ['New York', 'San Francisco'],
    education: 'Bachelors',
    skills: ['JavaScript', 'React', 'Node.js'],
    postedDate: 'Today',
    image: 'https://cdn-icons-png.flaticon.com/512/732/732221.png',
  },
];

const applliedjobscount = '30';

const AppliedJobsComponent: React.FC = () => {
  return (
    <div className='js-pg-hg'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='header-sub-height text-start px-2'>
              <h2 className='d-flex justify-content-start fw-bold h3'>You Applied {applliedjobscount}  Jobs</h2>
              <span className='text-secondary'>The recruiters approach to you soon!</span>
            </div>
            <div className='row row-cols-1 row-cols-md-3 g-4'>
              {AppliedJobs.map((job) => (
                <div key={job.id} className='col'>
                  <JobCardReUse
                    key={job.id}
                    jobRole={job.jobRole}
                    companyName={job.companyName}
                    package={job.package}
                    education={job.education}
                    experience={job.experience}
                    locations={job.locations}
                    skills={job.skills}
                    postedDate={job.postedDate}
                    image={job.image}
                    removeMarginBottom          
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        </div>
    </div>
  );
};

export default AppliedJobsComponent;