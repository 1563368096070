
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './JobProfileForms.css';
import { Card } from 'react-bootstrap';
import VerticalProgressBar from '../../reusable/verticalprogressbar/VerticalProgressBar';
import ProfileForm from '../../forms/profileform/ProfileForm';
import SkillsForm from '../../forms/skillsform/SkillsForm';
import Education from '../../forms/education/Education';
import Preferences from '../../forms/preferences/Preferences';
import UploadCv from '../../forms/uploadcv/UploadCv';
import WorkExperience from '../../forms/workexperience/WorkExperience';


interface Step {
  label: string;
  class: string;
}

interface JobProfileFormsProps {
  steps: Step[];
}

const JobProfileForms: React.FC<JobProfileFormsProps> = ({ steps }) => {
  const [activeForm, setActiveForm] = useState<string>('profile');

  const userName = 'DenCook!';
  const profileCompleted = '10%';

  const handleSubmit = (data: any) => {
    console.log(data);
  };

  const handleStepClick = (label: string) => {
    setActiveForm(label.toLowerCase().replace(' ', '-'));
  };

  const navigate = useNavigate();
  const handleSubmit2 = () => {
    navigate('/jobSeekerDashboard');
  };


  const renderForm = () => {
    switch (activeForm) {
      case 'profile':
        return <ProfileForm onSubmit={handleSubmit} />;
      case 'key-skills':
        return <SkillsForm onSubmit={handleSubmit} />;
        case 'education':
          return <Education onSubmit={handleSubmit} />;
          case 'preferences':
            return <Preferences onSubmit={handleSubmit} />;
            case 'upload-cv':
              return <UploadCv onSubmit={handleSubmit} />;
              case 'work-experience':
                return <WorkExperience onSubmit={handleSubmit} />;
      default:
        return null;
    }
  };

  return (
    <div>
      <div className='js-pg-hg'>
        <div className='container'>
          <div className='header-sub-height'>
            <div className='d-flex justify-content-start h3 fw-bold'>Welcome {userName}</div>
            <span className='d-flex justify-content-start align-items-center'>Your profile {profileCompleted} Completed <div className='btn btn-sm btn-outline-primary px-3 small s-6 py-1 mx-2 fw-medium' onClick={handleSubmit2}>Skip</div></span>
          </div>
          <div className='row'>
            <div className='col-md-3'>
              <Card>
                <Card.Body>
                  <div className="py-2">
                    <VerticalProgressBar steps={steps} onStepClick={handleStepClick} />
                  </div>
                </Card.Body>
              </Card>
            </div>
            <div className='col-md-9'>
              {renderForm()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobProfileForms;
