import React, { useState } from 'react';
import SkillsForm from '../../forms/skillsform/SkillsForm';
import '../../../forms.css';

import { Button } from 'react-bootstrap';



import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { MdOutlineCalendarToday } from "react-icons/md";


interface ProfileFormProps {
  onSubmit: (data: PersonalInfoData) => void;
}

interface PersonalInfoData {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  gender: string;
  about: string;
  industry: string;
  role: string;
  state: string;
  city: string;
  pincode: string;
}

const JobProfileForms: React.FC<ProfileFormProps> = ({ onSubmit }) => {
  const [formData, setFormData] = useState<PersonalInfoData>({
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    gender: '',
    about: '',
    industry: '',
    role: '',
    state: '',
    city: '',
    pincode: '',
  });

  const [activeForm, setActiveForm] = useState<'profile' | 'skills'>('profile');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleProfileSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit(formData);
    setActiveForm('skills');
  };

  const renderForm = () => {
    switch (activeForm) {
      case 'profile':
        return (
          <form onSubmit={handleProfileSubmit} className="my-form">
            <legend>
              <h6 className="fw-medium fs-4">Personal Information</h6>
              <hr className="w-100 border-color-2" />
            </legend>

            <div className="row">
              {/* First Name */}
              <label className="col-md-6 col-sm-12 mb-4">
                First Name
                <input
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                  className="form-control-custom mt-2"
                  placeholder="Enter your first name"
                />
              </label>
              {/* Last Name */}
              <label className="col-md-6 col-sm-12 mb-4">
                Last Name
                <input
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                  className="form-control-custom mt-2"
                  placeholder="Enter your last name"
                />
              </label>



              <label className="col-md-6 col-sm-12 mb-4">
                Date of Birth
                <input
                  type="date"
                  name="dateOfBirth"
                  value={formData.dateOfBirth}
                  onChange={handleChange}
                  required
                  className="form-control-custom mt-2"
                  placeholder="Select your date of birth"
                />
              </label>




<label className="col-md-6 col-sm-12 mb-4">
  Gender
  <select
    name="gender"
    value={formData.gender}
    onChange={handleChange}
    required
    className="form-select mt-2 form-control-custom"
  >
    <option value="" disabled hidden>
      Select
    </option>
    <option value="male">Male</option>
    <option value="female">Female</option>
    <option value="other">Other</option>
  </select>
</label>





              {/* About You */}
              <label className="col-md-12 col-sm-12 mb-4">
                About You
                <textarea
                  name="about"
                  value={formData.about}
                  onChange={handleChange}
                  rows={4}
                  required
                  className="form-control-custom mt-2"
                  placeholder="Tell us about yourself"
                />
              </label>
            </div>

            <legend className='pt-2'>
              <h6 className="mt-4 fw-medium fs-4">Professional Information</h6>
              <hr className="w-100 border-color-2" />
            </legend>
            <div className="row">
              {/* Industry */}
              <label className="col-md-6 col-sm-12 mb-4">
                Industry
                <select
                  name="industry"
                  value={formData.industry}
                  onChange={handleChange}
                  required
                  className="form-select form-control-custom mt-2"
                >
                  <option value="">Select</option>
                  <option value="it">IT & Technology</option>
                  <option value="pharmacy">Pharmacy</option>
                  <option value="medical">Medical</option>
                </select>
              </label>
              {/* Role */}
              <label className="col-md-6 col-sm-12 mb-4">
                Role
                <input
                  type="text"
                  name="role"
                  value={formData.role}
                  onChange={handleChange}
                  required
                  className="form-control-custom mt-2"
                  placeholder="Enter your role"
                />
              </label>
            </div>

            <legend className='pt-2'>
              <h6 className="fw-medium fs-4 mt-4">Location</h6>
              <hr className="w-100 border-color-2" />
            </legend>
            <div className="row">
              {/* State */}
              <label className="col-md-6 col-sm-12 mb-4">
                State
                <select
                  name="state"
                  value={formData.state}
                  onChange={handleChange}
                  required
                  className="form-select form-control-custom mt-2"
                >
                  <option value="">Select</option>
                  <option value="Alaska">Alaska</option>
                  <option value="California">California</option>
                  <option value="Florida">Florida</option>
                  <option value="Hawaii">Hawaii</option>
                  <option value="Kansas">Kansas</option>
                  <option value="Massachusetts">Massachusetts</option>
                  <option value="New Jersey">New Jersey</option>
                </select>
              </label>
              {/* Pincode */}
              <label className="col-md-6 col-sm-12 mb-4">
                Zipcode
                <input
                  type="text"
                  name="pincode"
                  value={formData.pincode}
                  onChange={handleChange}
                  required
                  className="form-control-custom mt-2"
                  placeholder="Enter your zipcode"
                />
              </label>
            </div>
            <Button type="submit" className="btn-forms-primary">Save and Continue</Button>
          </form>
        );
      case 'skills':
        return <SkillsForm onSubmit={onSubmit} />;
      default:
        return null;
    }
  };

  return <div>{renderForm()}</div>;
};

export default JobProfileForms;
